import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UtilsService } from "@core/services/utils.service";
import { AuthService } from "@modules/auth/services/auth.service";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private router: Router, public utils: UtilsService, public auth: AuthService) {
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.router.navigateByUrl('/live');
    }
  }
}
