import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TwoFactorComponent } from './components/two-factor/two-factor.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { MaterialsModule } from '../../materials/materials.module';

@NgModule({
  declarations: [
    SignInComponent,
    TwoFactorComponent,
  ],
  exports: [
    SignInComponent
  ],
  imports: [
    MaterialsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
  ]
})
export class AuthModule {
}
