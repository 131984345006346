import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AuthService } from '@modules/auth/services/auth.service';
import { SignInComponent } from '@modules/auth/components/sign-in/sign-in.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Home';

  constructor(public auth: AuthService, private router: Router, private dialog: MatDialog) {
  }

  selectedMenu(page: any): string {
    return (page === this.router.url || this.router.url.indexOf(page) !== -1) ? 'selected-menu' : '';
  }

  landingPage(): boolean {
    return (!('/home' === this.router.url || this.router.url === '/' || this.router.url === '/webview'
      || this.router.url === '/privacy-policy' || this.router.url === '/terms-of-use'
      || this.router.url === '/marketing' || this.router.url === '/delete-account'));
  }

  navigateTo(page: any): void {
    this.router.navigate([page]);
  }

  openSignIn(): void {
    const dialogRef = this.dialog.open(SignInComponent, {
      disableClose: true,
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((status) => {
      // console.log(status);
    });
  }

  onActivate(event: any): void {
    if (this.auth.getUserData() && this.auth.getUserData().status === 'PENDING' && window.location.href.indexOf('profile') === -1) {
      this.router.navigateByUrl('/profile');
    }
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
