
<div class="landing-page" [class.mobile]="utils.isMobile()">
  <div class="w-7 landing-image">
    <h1>Delete Your Account!</h1>
    <p>Firstly, you must log in using your credentials. Next,
      kindly provide the reason for your departure, and once submitted,
      we will proceed with the account deletion process.</p>
  </div>
  <div class="w-5 flex">
    <img src="assets/logo.png" width="120">
    <h1 *ngIf="!deletedSuccess">Login</h1>
    <h1 *ngIf="deletedSuccess">Deleted</h1>
    <div class="w-full">
      <div class="signin table border" *ngIf="!deletedSuccess">
        <div class="w-12">
          <form [formGroup]="loginForm" class="signin-form" autocomplete="off">
            <input autocomplete="false" name="hidden" type="hidden" class="hidden">
            <div class="form-group w-12">
              <mat-label class="w-full">Phone number</mat-label>
              <input class="w-11" matInput id="phone" type="tel" placeholder="Phone" name="phone" formControlName="phone" required />
            </div>
            <div class="form-group w-12">
              <mat-label class="w-full">Password</mat-label>
              <input  class="w-11" matInput id="password" placeholder="Password" name="password" [type]="hide ? 'password' : 'text'" formControlName="password" required/>
              <mat-icon class="eye-password" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
            <div>

            </div>
          </form>
        </div>
        <div class="flex center">
          <button class="w-11 button"  mat-button  (click)="onSubmit()">Sign In</button>
        </div>
        <div class="clear-both"></div>
      </div>
      <div class="signin table border" *ngIf="deletedSuccess">
        <div>
          Your Account is Now deleted thanks for your time
        </div>
      </div>
    </div>
  </div>
</div>
