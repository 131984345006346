import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SignInComponent } from '@modules/auth/components/sign-in/sign-in.component';
import { AuthService } from '@modules/auth/services/auth.service';
import { User } from '@modules/auth/models/user.model';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { UtilsService } from '@core/services/utils.service';
import { DataService } from '../../services/data.service';
import { SystemSearchComponent } from './system-search/system-search.component';
import { Observable, Subscription } from 'rxjs';
import { Howl } from 'howler';
import { ChatService } from '@core/services/chat.service';

interface INotification {
  id: number;
  key: string;
  read: boolean;
  reservation_id: number;
  subject: string;
  body: string;
  created: any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user!: User | null;
  notifications: INotification[] = [];
  newNotification = false;
  notificationPage = 1;
  notificationInterval: any;
  systemSearchOpened = false;
  newMessage = false;
  senderId = 0;
  lastNewMessageDate: any;
  item$: Observable<any[]> | undefined;
  subscription!: Subscription;
  chatCounts$!: Observable<number>;
  @ViewChild(MatMenuTrigger) matMenu: MatMenuTrigger | undefined;
  isAdmin: boolean = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public utils: UtilsService,
    public auth: AuthService,
    private dataService: DataService,
    public firestore: Firestore,
    private chatService: ChatService
  ) {}

  @HostListener('window:keyup', ['$event'])
  SearchShortcut(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'f' && event.shiftKey) {
      if (!this.systemSearchOpened) {
        this.systemSearch();
      }
    }
  }

  ngOnInit(): void {
    this.chatService.getUnreadChats();
    this.subscription = this.auth.userData.subscribe((user) => {
      this.user = user;
    });
    this.isAdmin = this.user?.type ==='ADMIN' ?? false;
    this.getLastMessages();
    this.getNotifications(false);
    this.notificationInterval = setInterval(
      () => this.getNotifications(),
      10000
    );
    this.chatCounts$ = this.chatService.unreadChatCount$.asObservable();
  }

  getNotifications(retry = true) {
    if (this.matMenu?.menuOpen && retry) {
      return;
    }
    return this.dataService
      .get('admin/users/notifications?page_size=5', retry)
      .then((data) => {
        if (data.status_code === 200) {
          const _notifications: INotification[] = data.data;
          this.newNotification = _notifications.some(
            (notification) => !notification.read
          );
          if (retry && _notifications[0].id !== this.notifications[0].id) {
            this.playSound();
          }
          this.notifications = _notifications;
        } else {
          this.utils.openSnackBar(data.data.message, 5000, 'error');
        }
      });
  }

  loadMoreNotification($event: MouseEvent) {
    $event.stopPropagation();
    return this.dataService
      .get(
        `admin/users/notifications?page_size=5&page=${
          this.notificationPage + 1
        }`,
        true
      )
      .then((data) => {
        if (data.status_code === 200) {
          this.notificationPage += 1;
          this.notifications.push(...data.data);
        } else {
          this.utils.openSnackBar(data.data.message, 5000, 'error');
        }
      });
  }

  TopMenuSelected(page: any): string {
    return page === this.router.url ? 'selected-menu' : '';
  }

  navigateTo(page: any): void {
    this.router.navigate([page]);
  }

  logout(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '350px';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: 'Logout',
      id: 0,
      api: 'close',
      class: 'accent',
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((status) => {
      if (status === 'yes') {
        this.auth.logout();
      }
    });
  }

  openSignIn(): void {
    const dialogRef = this.dialog.open(SignInComponent, {
      disableClose: true,
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((status) => {
      // console.log(status);
    });
  }

  systemSearch() {
    this.systemSearchOpened = true;
    const dialogRef = this.dialog.open(SystemSearchComponent, {
      width: '60vw',
      panelClass: 'search-panel',
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((status) => {
      this.systemSearchOpened = false;
    });
  }

  // TODO: Refactor and fix this functionality
  async getLastMessages() {
    // const docsSnap = await getDocs(collection(this.firestore, environment.firestore_chat));
    // docsSnap.forEach(doc => {
    //   if (doc.data()['sender_id'] == doc.id) {
    //     this.newMessage = true;
    //     this.lastNewMessageDate = doc.data()['date']
    //     this.senderId = parseInt(doc.id)
    //   }
    // });
    // const collections = collection(this.firestore, environment.firestore_chat, this.senderId.toString(), 'chatroom');
    // const data = query(collections, orderBy("date", "desc"));
    // this.item$ = collectionData(data);
    // this.item$.subscribe(() => {
    //   // TODO: Solve this issue
    //   this.item$?.forEach(doc => {
    //     // if (this.lastNewMessageDate.seconds === doc[0].date.seconds) {
    //     //   this.playSound()
    //     // }
    //   })
    // });
  }

  readNotification(notification: any) {
    this.dataService
      .get('notifications/' + notification.id + '/read')
      .then((data) => {
        if (data.status_code === 200) {
          if (notification.reservation_id) {
            this.router.navigateByUrl(
              'reservations?search=' + notification.reservation_id
            );
          } else if (notification.key == 'PROFILE') {
            this.router.navigateByUrl(
              'drivers?search=' + notification.body.split(' Driver,')[0]
            );
          } else {
            this.ngOnInit();
          }
        } else {
          this.utils.openSnackBar(data.data.message, 5000, 'error');
        }
      });
  }

  playSound() {
    const sound = new Howl({
      src: ['assets/notification.wav'],
    });
    sound.play();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.notificationInterval);
  }
}
