import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./home/home.component";
import { LandingComponent } from "./landing/landing.component";
import { MessagingComponent } from "./messaging/messaging.component";
import { HelpAdminComponent } from "./help-admin/help-admin.component";
import { HelpAdminWebviewComponent } from "./help-admin-webview/help-admin-webview.component";
import { DeleteAccountComponent } from "./delete-account/delete-account.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { MarketingComponent } from "./marketing/marketing.component";
import { permissionGuard } from '@core/guards/permission.guard';
import { authGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'home', component: LandingComponent },
  { path: 'live', component: HomeComponent, canMatch: [authGuard] },
  { path: 'messaging', component: MessagingComponent, canMatch: [authGuard] },
  { path: 'help', component: HelpAdminComponent, canMatch: [authGuard] },
  { path: 'delete-account', component: DeleteAccountComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'webview', component: HelpAdminWebviewComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'marketing', component: MarketingComponent },
  {
    path: 'profiles',
    canMatch: [authGuard],
    loadChildren: () => import('./modules/profiles/profiles.module').then(m => m.ProfilesModule)
  },
  {
    path: 'reservations',
    canMatch: [authGuard, permissionGuard()],
    loadChildren: () => import('./modules/reservation/reservation.module').then(m => m.ReservationModule)
  },
  {
    path: 'drivers',
    canMatch: [authGuard, permissionGuard()],
    loadChildren: () => import('./modules/driver/driver.module').then(m => m.DriverModule)
  },
  {
    path: 'invoicing',
    canActivate: [authGuard],
    loadChildren: () => import('./modules/invoicing/invoicing.module').then(m => m.InvoicingModule)
  },
  {
    path: 'vehicles',
    canActivate: [authGuard],
    loadChildren: () => import('./modules/vehicle/vehicle.module').then(m => m.VehicleModule)
  },
  {
    path: 'settings',
    canMatch: [authGuard],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
