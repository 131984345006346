<div class="nav-bar" [class.tablet-ipad]="utils.isTablet()">
    <div
            class="logo flex center"
            (click)="
      utils.isDesktop() ? navigateTo('/live') : navigateTo('/reservations')
    "
    >
        <img src="/assets/logo.svg" alt="Envoy" height="28"/>
    </div>
    <div style="margin: 0 auto" class="top-menu" *ngIf="utils.isDesktop()">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <button
                (click)="navigateTo('/live')"
                [class]="TopMenuSelected('/live')"
                mat-button
        >
            <mat-icon
                    svgIcon="live_icon{{ TopMenuSelected('/live') === '' ? '' : '_open' }}"
            />
            Live Trips
        </button>
        <button
                *appHasPermission="'admin/reservations'"
                routerLink="/reservations"
                routerLinkActive="selected-menu"
                #rlTrip="routerLinkActive"
                mat-button
        >
            <mat-icon svgIcon="trips_icon{{ rlTrip.isActive ? '_open' : '' }}"/>
            Trips
        </button>
        <button
                *appHasPermission="['admin/drivers']"
                routerLink="/drivers"
                routerLinkActive="selected-menu"
                #rlDriver="routerLinkActive"
                mat-button
        >
            <mat-icon svgIcon="drivers_icon{{ rlDriver.isActive ? '_open' : '' }}"/>
            Companion Drivers
        </button>
        <button envoyCheckEnv
                *appHasPermission="['admin/vehicles']"
                routerLink="/vehicles"
                routerLinkActive="selected-menu"
                #rlVehicle="routerLinkActive"
                mat-button
        >
            <mat-icon svgIcon="cov:vehicle_icon{{ rlVehicle.isActive ? '_open' : '' }}"/>
            Vehicles
        </button>
        <button
                *appHasPermission="['admin/customers', 'admin/companies', 'admin/corporates']"
                routerLink="/profiles"
                routerLinkActive="selected-menu"
                #rlCustomer="routerLinkActive"
                mat-button
        >
            <mat-icon svgIcon="story{{ rlCustomer.isActive ? '_open' : '' }}"/>
            Profiles
        </button>
        <button *appHasPermission="'admin/invoices'"
                routerLink="/invoicing"
                routerLinkActive="selected-menu"
                #rlInvoice="routerLinkActive"
                mat-button
        >
            <mat-icon svgIcon="invoice{{ rlInvoice.isActive ? '_open' : '' }}"/>
            Invoicing
        </button>
        <button
                routerLink="/settings"
                routerLinkActive="selected-menu"
                #rlSetting="routerLinkActive"
                mat-button
        >
            <mat-icon
                    svgIcon="settings_icon{{ rlSetting.isActive ? '_open' : '' }}"
            />
            Settings
        </button>
    </div>
  <ng-container *ngIf="utils.isDesktop()">
    <button
            *appHasPermission="'admin/drivers'"
            (click)="navigateTo('/messaging')"
            [class]="TopMenuSelected('/messaging')"
            mat-button
    >
        <mat-icon
                aria-hidden="false"
                [attr.aria-hidden]="!(chatCounts$ | async)"
                [matBadgeHidden]="!(chatCounts$ | async)"
                svgIcon="sms_icon{{
        TopMenuSelected('/messaging') === '' ? '' : '_open'
      }}"
                matBadgeColor="warn"
                matBadge="&#8288;"
        />
        <ng-container *ngIf="TopMenuSelected('/messaging') !== ''"
        >Messages
        </ng-container
        >
    </button>
    <button
            *ngIf="isAdmin"
            mat-button
            class="{{ t.menuOpen ? 'selected-menu' : '' }}"
            [matMenuTriggerFor]="notificationMenu"
            #t="matMenuTrigger"
    >
        <mat-icon
                aria-hidden="false"
                [attr.aria-hidden]="!newNotification"
                [matBadgeHidden]="!newNotification"
                svgIcon="notification_icon{{ t.menuOpen ? '_open' : '' }}"
                matBadgeColor="warn"
                matBadge="&#8288;"
        />
        <ng-container *ngIf="t.menuOpen">Notification</ng-container>
    </button>
    <mat-menu #notificationMenu="matMenu" class="notification-menu">
        <div class="w-full notification">
            <div class="w-full" *ngIf="notifications.length > 0">
                <div class="w-6">
                    <mat-icon svgIcon="notification_icon"></mat-icon>
                    <span>Notifications</span>
                </div>
                <mat-divider></mat-divider>
            </div>
            <ng-container *ngFor="let notification of notifications">
                <button
                        [class.not_read]="!notification.read"
                        mat-menu-item
                        *ngIf="notification"
                        (click)="readNotification(notification)"
                >
                    <mat-icon matIconPrefix svgIcon="{{ notification.key }}"></mat-icon>
                    <span>
            <h2>{{ notification.subject }}</h2>
            <p [innerHTML]="notification.body"></p>
          </span>
                </button>
            </ng-container>
            <mat-divider/>
            <button
                    mat-menu-item
                    (click)="loadMoreNotification($event)"
                    *ngIf="notifications.length"
            >
                <mat-icon matIconPrefix style="min-height: unset" fontIcon="refresh"/>
                <span>Load More</span>
            </button>
            <div *ngIf="notifications?.length == 0">
                <img src="assets/NoNotification.png" alt="Empty View" width="100%"/>
                <h2>No Notification Available</h2>
            </div>
        </div>
    </mat-menu>
  </ng-container>
  <mat-divider
            vertical
            class="header-divider"
            [class.mobile]="utils.isMobile()"
    ></mat-divider>

    <button
            *ngIf="utils.isDesktop() && isAdmin"
            mat-button
            class="{{ systemSearchOpened ? 'selected-menu' : '' }}"
            (click)="systemSearch()"
    >
        <mat-icon
                svgIcon="search_normal_icon{{ systemSearchOpened ? '_open' : '' }}"
        />
        <ng-container *ngIf="systemSearchOpened">System Search</ng-container>
    </button>

    <button
            type="button"
            mat-button
            [matMenuTriggerFor]="dropMenu"
            #x="matMenuTrigger"
            *ngIf="utils.isMobile()"
    >
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #dropMenu="matMenu">
        <button
                [class.mobile]="utils.isMobile()"
                (click)="navigateTo('/reservations')"
                [class]="TopMenuSelected('/reservations')"
                mat-button
        >
            <mat-icon
                    svgIcon="trips_icon{{
          TopMenuSelected('/reservations') === '' ? '' : '_open'
        }}"
            />
            Trips
        </button>
        <button
                [class.mobile]="utils.isMobile()"
                (click)="navigateTo('/drivers')"
                [class]="TopMenuSelected('/drivers')"
                mat-button
        >
            <mat-icon
                    svgIcon="drivers_icon{{
          TopMenuSelected('/drivers') === '' ? '' : '_open'
        }}"
            />
            Companion Drivers
        </button>
        <button
                [class.mobile]="utils.isMobile()"
                (click)="navigateTo('/customers')"
                [class]="TopMenuSelected('/customers')"
                mat-button
        >
            <mat-icon
                    svgIcon="customers_icon{{
          TopMenuSelected('/customers') === '' ? '' : '_open'
        }}"
            />
            Customers
        </button>
    </mat-menu>

    <button
            *ngIf="utils.isDesktop() && !user"
            class="signin-button"
            (click)="openSignIn()"
            mat-stroked-button
    >
        Sign in
    </button>

    <button *ngIf="user" mat-button [matMenuTriggerFor]="profile">
        <span>{{ user.first_name }}</span>
    </button>
    <mat-menu #profile="matMenu">
        <button *ngIf="user" class="logout-button" (click)="logout()" mat-menu-item>
            Logout
        </button>
    </mat-menu>
</div>
<div id="playSound"></div>
