<mat-tab-group color="primary" animationDuration="0" #matTabGroup>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon svgIcon="live_icon{{matTabGroup.selectedIndex==0?'_open':''}}"></mat-icon>
      Live Trips
    </ng-template>

    <div class="col-12 table border">
      <div>
        <div>
          <div>
            <p>In the Live Trips Page, the admin can monitor all live trips and see active trips under the "Currently Active Trips" section. The "Car Types" section displays the car types for active trips. Each section shows counts for all active trip statuses in different colors.</p>
            <br>
            <p>The admin can filter on Trip Stations and Car Types, and after filtering, a "Reset All" filter will be displayed to reset any filtration.</p>
            <br>
            <p>The admin can search for specific information about drivers or trips using the general search marked as "2" in the above Live Trips picture. In the "Currently Active Trips" section, the admin can see statistics about total active trips and stations (En route, On Board, On Location, and Wait) to make monitoring easier.</p>
            <br>
            <p>In the "Car Types" section, the admin can see statistics about all active trips' car types (SUV, Sedan, WAV, and Any) to make following them easier.</p>
            <br>
            <p>By clicking on any active trip on the map, the admin can see summary trip information for that trip. All active trips are displayed on the map in different colors according to the trip station. Zooming in on any active trip will display the full route to the admin, and clicking at any point on the route line will display a summary about that trip at that position.</p>
            <br>
          </div>
          <img src="assets/help/live_trips_1.png" alt="" width="90%"/>
        </div>
        <div>
          <ol>
            <h2>1- Filtering Trips and Car Types</h2>
            <br>
            <p>The admin could filter on Trip Stations, and Car Types. After filtering, Reset All filter will be displayed so the admin could reset any filtration.</p>
            <br>

            <img src="assets/help/live-1.png" alt="" width="30%"/>
            <img src="assets/help/live-2.png" alt="" width="30%"/>
            <img src="assets/help/live-3.png" alt="" width="30%"/>
<br>
            <h2>2- General Search</h2>
            <p>The admin could search on any specific information he needs about the drivers, or trips using general search which marked in 2 in the above Live Trips picture.</p>
            <br>
            <h2>3- Statistics for Currently Active Trips</h2>
            <p>In the Currently Active Trips section, the admin could see statistics about total active trips, and another statistic about stations (En route, On Board, On Location, and Wait) each one in different count color to distinguish between them. And make monitoring easier.</p>
            <br>
            <h2>4- Statistics for Car Types</h2>
            <p>In the Car Types section, the admin could see statistics about all active trips’ car types (SUV, Sedan, WAV, And Any) each one in different count color to distinguish between them. And make following them easier.</p>
            <br>
            <h2>5- Interactive Map with Trip Information</h2>
            <p>The admin could click on any active trip on the map, each circle will display in different color according to the trip station, once the admin clicks on any active trip on the map, summary trip information will be displayed to him.</p>
            <br>
            <h2>6- All Active Trips will be displayed on the map in different colors according to the trip station.</h2>
            <img alt="" src="assets/help/live-6.png" width="80%">
            <br>
            <h2>7- Zoom in for any active trip will display the full route to the admin, clicking at any point in the route line will display a summery about this trip at this position.</h2>
          </ol>
        </div>
      </div>
    </div>
  </mat-tab>
 <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon svgIcon="trips_icon{{matTabGroup.selectedIndex==1?'_open':''}}"></mat-icon>
      Trips
    </ng-template>

   <div class="col-12 table border">
     <div >
       <div >
         <p>In this page the admin could view all trip types in the system, He will see general statistics about all trips, He could deeply check trips information, He could do few quick filtration types, He could search for any trip specific details, He could customize table view by adding removing columns, and He could do deep filtration on the table.</p>
         <p>Bellow picture with numbers on it, and explanation for each number.</p>
         <img src="assets/help/trips.png" width="90%" alt="">
         <br>
         <br>
         <h2>1- In this section the admin will see Total Trips counts in Gray color, Active trips counts in Green color, Upcoming Trips counts in Blue color, and Unassigned trips counts in Orange color.</h2>
         <br>
         <br>
         <h2>2- In this section the user could use the quick filters buttons (All, Active, Unassigned, and Urgent) by clicking on each one related trip only will display in the table, and it will display empty table if there are no related trips to display, like the active ones in the below picture.</h2>
         <img src="assets/help/trips-2.png" width="90%" alt="">
         <img src="assets/help/trips-3.png" width="90%" alt="">
         <img src="assets/help/trips-4.png" width="90%" alt="">
         <img src="assets/help/trips-5.png" width="90%" alt="">
         <br>
         <br>
          <h2>3- In this Section the admin could import the trips from FastTrack.</h2>
         <img src="assets/help/trips-7.png" width="90%" alt="">

         <br>
         <br>
         <h2>4- Admin could search for any trip code, passenger, and name.</h2>
         <img src="assets/help/trips-8.png" width="90%" alt="">
         <br>
         <br>
         <h2>5- In this section Customize table popup will display to the admin, so he could add, and remove columns from the Trips table. Which means Columns showed will be displayed as the Trips table view, and the Hidden Columns will not be displayed as the Trips table view.</h2>
         <img src="assets/help/trips-10.png" width="90%" alt="">
         <img src="assets/help/trips-11.png" width="90%" alt="">
         <br>
         <br>
         <h2>6- In this section Table Filters popup will display to the admin so he could filter on existing options, then apply this filter, in this case the data which related to the applied filter should be displayed in the table only.
           The admin will Have the option to reset any applied filter too see the bellow picture.</h2>
         <img src="assets/help/trips-12.png" width="90%" alt="">
         <h3>Admin could Apply filter using:</h3>
         <br>
         <ul>
           <li>Pickup Date</li>
           <li>Car Type</li>
           <li>Company</li>
           <li>Area</li>
         </ul>
         <br>
         <h3> Admin Could reset any applied filter using Reset Filter too.</h3>
         <br>
         <img src="assets/help/trips-14.png" width="90%" alt="">
         <br>
         <br>
         <h2>7- Urgent Trip is a flag given to any urgent trip, so ASAP drivers will take I, Or admin could mark a trip as urgent and assign it automatically to the driver.</h2>
         <img src="assets/help/trips-15.png" width="90%" alt="">
         <br>
         <br>
         <h2>8- Admin also could see each trip details by clicking on any trip raw inside the table</h2>
         <img src="assets/help/trips-16.png" width="90%" alt="">
       </div>
     </div>
   </div>
  </mat-tab>

  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon svgIcon="drivers_icon{{matTabGroup.selectedIndex==2?'_open':''}}"></mat-icon>
      Companion Drivers
    </ng-template>
    <div class="col-12 table border">
      <div>
        <p>In the Companion Drivers Page, the admin will be able to monitor all Drivers, see all drivers’ profiles information, Filter on drivers’ information, and Customize Drivers information table.
          In each section the admin will see counts for all active Drivers statuses in different colors () sections in the below picture.</p>
        <br>
        <img src="assets/help/driver.png" width="90%" alt="">
        <br>
        <h2>In this section the Admin also could view each single drivers’ details by clicking on any driver raw inside the table</h2>
        <br>
        <h2>1- In this section the admin will see Total Drivers Count in Blue color, Active Drivers counts in Green color, About To Expire count in Orange color, and Expired count in Read color.</h2>
        <br>
        <br>
        <h2>2- In this section the user could use the quick filters buttons (All, Active, Approval Needed, and Deactivated) by clicking on each one related driver only will display in the table, and it will display empty table if there are no related driver’s information to display.</h2>
        <img src="assets/help/driver-2.png" width="90%" alt="">
        <img src="assets/help/driver-3.png" width="90%" alt="">
        <img src="assets/help/driver-4.png" width="90%" alt="">
        <img src="assets/help/driver-5.png" width="90%" alt="">
        <br>
        <br>
        <h2>3- Admin could search for any driver name, and phone.</h2>
        <br>
        <img src="assets/help/driver-6.png" width="90%" alt="">
        <br>
        <br>
        <h2>4- In this section Customize table popup will display to the admin, so he could add, and remove columns from the Trips table. Which means Columns showed will be displayed as the Companion Drivers table view, and the Hidden Columns will not be displayed as the Companion Drivers table view.</h2>
        <img src="assets/help/driver-7.png" width="90%" alt="">
        <img src="assets/help/driver-8.png" width="90%" alt="">
        <br>
        <h2>5- In this section Table Filters popup will display to the admin so he could filter on existing options, then apply this filter, in this case the data which related to the applied filter should be displayed in the table only. The admin will Have the option to reset any applied filter too see the bellow picture.</h2>
        <br>
        <img src="assets/help/driver-9.png" width="90%" alt="">
        <br>
        <br>
        <h2>6- Admin also could see each Driver details by clicking on any Driver raw inside the table.</h2>
        <br>
        <img src="assets/help/driver-10.png" width="90%" alt="">
        <h2>7- Admin could Set EmployeeID, And Set Area Filters</h2>
        <img src="assets/help/driver-11.png" width="90%" alt="">

        <h2>8- Admin Could Activate, and Deactivate Drivers form Driver single detail popup too.</h2>
        <img src="assets/help/driver-12.png" width="90%" alt="">
        <img src="assets/help/driver-13.png" width="90%" alt="">

      </div>
    </div>
  </mat-tab>

  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon svgIcon="customers_icon{{matTabGroup.selectedIndex==3?'_open':''}}"></mat-icon>
      Customers
    </ng-template>

    <div class="col-12 table border">
      <div>
        <p>In the Customers Page, the admin will be able to monitor all Customers, see all customers profiles information, Filter on customers information, and Customize customer information table.</p>
        <p>In each section the admin will see counts for all customers statuses in different colors () sections in the below picture.</p>
        <p>In this section the Admin also could view each single customer details by clicking on any customer raw inside the table</p>
        <br>
        <br>
        <img src="assets/help/customer.png" width="90%" alt=""/>
        <br>
        <h2>1- In this section the admin will see Total customers Count in Blue color, Active Customers counts in Green color, and Recently Registered count in Orange color.</h2>
        <br>
        <br>
        <h2>2- Admin could search for any customer name, number, and email.</h2>
        <img src="assets/help/customer-2.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>3- In this section Customize table popup will display to the admin, so he could add, and remove columns from the Customers table. Which means Columns showed will be displayed as the Customers table view, and the Hidden Columns will not be displayed as the Companion Drivers table view.</h2>
        <img src="assets/help/customer-3.png" width="90%" alt=""/>
        <img src="assets/help/customer-4.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>4- In this section Table Filters popup will display to the admin so he could filter on existing options, then apply this filter, in this case the data which related to the applied filter should be displayed in the table only. The admin will Have the option to reset any applied filter too see the bellow picture.</h2>
        <img src="assets/help/customer-5.png" width="90%" alt=""/>
        <br>
        <br>
      </div>
    </div>
  </mat-tab>

  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon svgIcon="settings_icon{{matTabGroup.selectedIndex==4?'_open':''}}"></mat-icon>
      Settings Page
    </ng-template>
    <div class="col-12 table border">
      <div>
        <p>In Settings page Super Admin could see Three sections (Profile, App Configuration, and Admins)</p>
        <p>In Settings page Normal Admin could see Three sections (Profile, and App Configuration)</p>
        <br>
        <br>
        <h2>1- In the Settings Page, the admin will be able to View his/her profile, Edit profile, and Change password.</h2>
        <img src="assets/help/settings.png" width="90%" alt="">
        <br>
        <br>
        <h2>2- Change Password Popup:</h2>
        <img src="assets/help/settings-2.png" width="90%" alt="">
        <br>
        <br>
        <h2>3- In App Configuration Admin will be able to configure timing for all configuration save, and cancel it.</h2>
        <img src="assets/help/settings-3.png" width="90%" alt="">
        <br>
        <br>
        <h2>4- In Admins Section Super admin Will, be able to Add Normal Admin, Activate, Deactivate, and Reset Password for other normal Admins.</h2>
        <img src="assets/help/settings-4.png" width="90%" alt="">
        <br>
        <h2>Add New Normal Admin Popup:</h2>
        <img src="assets/help/settings-5.png" width="90%" alt="">
        <img src="assets/help/settings-6.png" width="90%" alt="">
        <img src="assets/help/settings-7.png" width="90%" alt="">
        <h2>Reset Password popup:</h2>
        <img src="assets/help/settings-8.png" width="90%" alt="">
        <h2>Activate Admin Popup:</h2>
        <img src="assets/help/settings-10.png" width="90%" alt="">

        <h2>Deactivate Normal Admin Popup:</h2>
        <img src="assets/help/settings-11.png" width="90%" alt="">

      </div>
    </div>
  </mat-tab>
  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon svgIcon="notification_icon{{matTabGroup.selectedIndex==5?'_open':''}}"></mat-icon>
      Dashboard Page
    </ng-template>
    <div class="col-12 table border">
      <div>
        <h2>- System Search</h2>
        <br>
        <br>
        <h2>1- In System Search component the admin could search in all Dashboard pages according to the entered text.</h2>
        <img src="assets/help/dashboard-1.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>2- Admin could use it from Live Trips Page:</h2>
        <img src="assets/help/dashboard-2.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>3- Admin could use it from Trips Page:</h2>
        <img src="assets/help/dashboard-3.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>4- Admin could use it from Companion Drivers Page:</h2>
        <img src="assets/help/dashboard-4.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>5- Admin could use it from Customers Page:</h2>
        <img src="assets/help/dashboard-5.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>6- Admin could use it from Settings Page:</h2>
        <img src="assets/help/dashboard-6.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>- Notification Center</h2>
        <br>
        <br>
        <h2>In This Component Super Admin, and Admin will see all new notifications from their drivers</h2>
        <img src="assets/help/dashboard-7.png" width="90%" alt=""/>
        <img src="assets/help/dashboard-8.png" width="90%" alt=""/>
        <br>
        <br>
        <h2>- Chatting Page</h2>
        <br>
        <br>
        <h2>In This Page the Super Admin, and Normal Admin will be able to chat with their drivers at any time.</h2>
        <img src="assets/help/dashboard-9.png" width="90%" alt=""/>
        <ol>
          <li>Admin, and Normal Admin will be able to search through their messages.</li>
          <li>Messages will be ordered most recent messages at the top.</li>
          <li>Message button will display a read circle if the Admin, and Normal Admin received any new message.</li>
        </ol>
        <img src="assets/help/dashboard-10.png" width="90%" alt=""/>
        <h2>Admin, and Normal Admin will be able to:</h2>
        <ol>
          <li>Current opened chat will be highlighted in different color.</li>
          <li>Admin, and Normal Admin will be able to start typing messages to any driver from their contacts.</li>
          <li>Admin, and Normal Admin will be able to click on sending messages as well.</li>
        </ol>
        <img src="assets/help/dashboard-11.png" width="90%" alt=""/>

      </div>
    </div>
  </mat-tab>

  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon svgIcon="sms_icon{{matTabGroup.selectedIndex==6?'_open':''}}"></mat-icon>
      Videos
    </ng-template>
    <div class="col-12 table border">
      <div>
        <h1>Filtering on Customer Page</h1>
        <video width="100%" controls>
          <source src="assets/videos/Filtering%20on%20Customers%20Tabe.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Filtering on Driver Page</h1>
        <video width="100%" controls>
          <source src="assets/videos/Filtering%20on%20Drivers%20Page.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Chatting</h1>
        <video width="100%" controls>
          <source src="assets/videos/Chatting.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Customer Infromation</h1>
        <video width="100%" controls>
          <source src="assets/videos/Customer%20information.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Customize Columns in Drivers Page</h1>
        <video width="100%" controls>
          <source src="assets/videos/Customize%20Coulmns%20in%20Drivers%20Page.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Driver Details</h1>
        <video width="100%" controls>
          <source src="assets/videos/DRIVER%20DETAILS.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>How To set Driver area filter</h1>
        <video width="100%" controls>
          <source src="assets/videos/How%20To%20set%20Driver%20area%20filter.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>How To Set employeeID</h1>
        <video width="100%" controls>
          <source src="assets/videos/How%20To%20set%20employeeID.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>How To use Quick Filters on Trops Page</h1>
        <video width="100%" controls>
          <source src="assets/videos/How%20to%20Use%20Quick%20Filters%20buttons%20on%20Trips%20Page.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Login</h1>
        <video width="100%" controls>
          <source src="assets/videos/Login.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Logout</h1>
        <video width="100%" controls>
          <source src="assets/videos/Logout.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Notifications</h1>
        <video width="100%" controls>
          <source src="assets/videos/Notifications.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Search inside drivers page</h1>
        <video width="100%" controls>
          <source src="assets/videos/Serach%20inside%20Drivers%20page.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Sort Driver Table</h1>
        <video width="100%" controls>
          <source src="assets/videos/Sort%20Driver%20Table.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Update Admin information</h1>
        <video width="100%" controls>
          <source src="assets/videos/Update%20Admin%20Information.mov">
        </video>
      </div>
    </div>
  </mat-tab>

  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon svgIcon="sms_icon{{matTabGroup.selectedIndex==6?'_open':''}}"></mat-icon>
      Reservation/Trip Videos
    </ng-template>
    <div class="col-12 table border">
      <div>
        <h1>Filtering on Trips</h1>
        <video width="100%" controls>
          <source src="assets/videos/Filtering%20on%20Trips.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Get Reservation From Fasttrack</h1>
        <video width="100%" controls>
          <source src="assets/videos/Get%20Reservation%20From%20Fasttack.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>How To use Quick Filters on Reservation Page</h1>
        <video width="100%" controls>
          <source src="assets/videos/How%20to%20Use%20Quick%20Filters%20buttons%20on%20Trips%20Page.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Show/Hide Columns trip Page</h1>
        <video width="100%" controls>
          <source src="assets/videos/Show%20Hide%20Columns%20in%20Trips%20Page.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Show Trip Details</h1>
        <video width="100%" controls>
          <source src="assets/videos/Show%20Trip%20Details.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Trips on Map 1</h1>
        <video width="100%" controls>
          <source src="assets/videos/Trip%20on%20map1.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>Trips on Map 2</h1>
        <video width="100%" controls>
          <source src="assets/videos/Trip%20on%20map2.mov">
        </video>
        <br>
        <br>
        <br>
        <br>
        <h1>View Reservation List</h1>
        <video width="100%" controls>
          <source src="assets/videos/View%20Trips_Reservations%20list%20.mov">
        </video>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
