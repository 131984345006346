import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '@core/services/data.service';
import { UtilsService } from "@core/services/utils.service";
import { AuthService } from '@modules/auth/services/auth.service';
import { User } from "@modules/auth/models/user.model";
import { Observable, Subscription } from "rxjs";
import {
  collection,
  collectionData,
  doc,
  Firestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  Timestamp
} from '@angular/fire/firestore';
import { environment } from "@env/environment";

interface IMessage {
  sender_name: string;
  sender_id: number;
  message: string;
  read?: Timestamp;
  date: any;
}

interface IChat {
  driver_id: string;
  data: IMessage;
}

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit, AfterViewChecked, OnDestroy {
  sendMessageForm: FormGroup;
  searchControl = new FormControl('');
  admin: User;
  drivers: User[] = [];
  driverData: User | undefined;
  chat$!: Observable<any[]>;
  chats$: IChat[] = [];
  selectedDriver = 0;
  unsubscribe$: any;
  subscription: Subscription | undefined;
  @ViewChild('scrollMe') scrollMe: any;
  @ViewChild('scrollDrivers') scrollDrivers: any;

  constructor(private dataService: DataService, private auth: AuthService, public utils: UtilsService,
              public router: Router, public dialog: MatDialog, private cdr: ChangeDetectorRef,
              public firestore: Firestore, private activeRoute: ActivatedRoute) {
    if (!this.auth.isAuthenticated()) {
      this.router.navigateByUrl('/landing');
    }
    this.sendMessageForm = new FormGroup({
      // @ts-ignore
      text: new FormControl(null, [this.utils.noWhitespaceValidator])
    });
    this.admin = this.auth.getUserData();
    this.activeRoute.queryParams.subscribe(
      params => {
        if (params['driver_id']) {
          this.selectedDriver = parseInt(params['driver_id']);
        }
      });
  }

  ngOnInit(): void {
    this.getDriversChats();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$();
    this.subscription?.unsubscribe();
  }

  /**
   * get latest chats of drivers from fire store
   */
  async getDriversChats() {
    let _loaded = false;
    const q = query(collection(this.firestore, environment.firestore_chat), orderBy('date', 'desc'));
    this.unsubscribe$ = onSnapshot(q, (snapshot) => {
      this.chats$ = [];
      snapshot.forEach(doc => {
        this.chats$.push({ driver_id: doc.id, data: doc.data() as IMessage });
      });
      this.getDriversData(_loaded);
      _loaded ||= true;
    });
  }

  /**
   * get the data of the drivers who have chats before
   */
  getDriversData(loaded: boolean) {
    const drivers = this.chats$.map(chat => chat.driver_id);
    this.dataService.get('admin/drivers?ids=' + drivers.join(), loaded).then(data => {
      if (data.status_code === 200) {
        this.drivers = data.data;

        if (this.selectedDriver !== 0 && this.selectedDriver !== this.driverData?.id) {
          this.getMessages(this.selectedDriver);
        }
      }
      this.sortDriversChats();
    });
  }

  /**
   * sort the drivers according to the chats order ignoring not found or inactive drivers chats
   */
  sortDriversChats() {
    const drivers: User[] = [];
    this.chats$.forEach(chat => {
      const _driver = this.drivers?.find(driver => driver.id === +chat.driver_id);
      if (_driver) {
        drivers.push(_driver);
      }
    });
    this.drivers = drivers;
  }

  /**
   * get chat messages of a driver
   * @param driver
   */
  getMessages(driver: number): any {
    this.selectedDriver = driver;
    this.driverData = this.getDriverData(driver);
    window.history.replaceState({}, '', 'messaging?driver_id=' + encodeURIComponent(this.selectedDriver))

    const collections = collection(this.firestore, environment.firestore_chat, driver.toString(), 'chatroom');
    const data = query(collections, orderBy("date"));
    this.chat$ = collectionData(data);
    this.subscription = this.chat$.subscribe(() => {
      const lstMsg = this.lastMessage(driver);
      if (lstMsg && !this.isChatRead(lstMsg)) {
        this.markMessageAsRead();
      }
      setTimeout(() => {
        if (this.scrollMe) {
          this.scrollMe.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 300);
    });
  }

  /**
   * get last chat message for a specific driver
   * @param driverId
   */
  lastMessage(driverId: number) {
    const data = this.chats$.find(chat => driverId === +chat.driver_id)?.data;
    return data?.sender_name ? data : undefined;
  }

  /**
   * check if the last msg in the chat has been read or not
   */
  isChatRead(message?: IMessage) {
    if (!message?.read) {
      return false;
    }
    return message.read.toDate() > message.date.toDate();
  }

  /**
   * mark the last message from a specific driver as read when open it
   */
  markMessageAsRead() {
    return updateDoc(doc(this.firestore, environment.firestore_chat, this.selectedDriver.toString()), {
      read: new Date()
    });
  }

  getDriverData(id: number): any {
    return this.drivers.find(driver => driver.id === id);
  }

  /**
   * add new message to chat and creating new chat if not existing before
   * @param text
   */
  async addChat(text: any) {
    const date_now = new Date()
    await setDoc(doc(this.firestore, environment.firestore_chat, this.selectedDriver.toString()), {
      message: text,
      date: date_now,
      sender_id: this.admin.id,
      sender_name: this.admin.first_name
    });
    await setDoc(doc(this.firestore, environment.firestore_chat, this.selectedDriver.toString(), 'chatroom', date_now.toString()), {
      message: text,
      sender_id: this.admin.id,
      sender_name: this.admin.first_name,
      date: date_now,
    });
    this.sendMessageForm.reset();
  }

  sendMessages(): any {
    if (this.selectedDriver && this.sendMessageForm.valid && this.sendMessageForm.get('text')?.value) {
      this.addChat(this.sendMessageForm.get('text')?.value);
      setTimeout(() => {
        if (this.scrollDrivers) {
          this.scrollDrivers.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 300);
    }
  }

  /**
   * filter drivers by name
   */
  filterDrivers(): void {
    if (!this.searchControl.value?.trim()) {
      this.getDriversChats();
      return;
    }
    this.dataService.get('admin/drivers?status=ACTIVE&search=' + this.searchControl.value).then(data => {
      if (data.status_code === 200) {
        this.resortDriversChats(data.data);
      }
    });
  }

  /**
   * resort chats of drivers after filtering data
   */
  resortDriversChats(drivers: any) {
    let _drivers: any[] = [];
    drivers?.forEach((item: any) => {
      if (this.lastMessage(item.id)?.date?.seconds) {
        _drivers.push({
          ...item,
          last_message_date: new Date(this.lastMessage(item.id)?.date?.seconds * 1000)
        });
      } else {
        _drivers.push({
          ...item,
          last_message_date: 0
        });
      }
    });
    _drivers.sort(function (a, b) {
      return b.last_message_date - a.last_message_date;
    })
    this.drivers = _drivers;
  }

}
