import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialsModule } from '../materials/materials.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { EmptySearchDialogComponent } from './components/empty-search-dialog/empty-search-dialog.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { SnackBarComponent } from './components/snackbar/snackbar.component';


@NgModule({
  declarations: [
    ProgressSpinnerComponent,
    ConfirmDialogComponent,
    SnackBarComponent,
    EmptySearchDialogComponent,
    ImageViewerComponent
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    ReactiveFormsModule
  ],

  exports: [
    ConfirmDialogComponent
  ]
})
export class SharedModule {
}
