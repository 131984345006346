import {Router} from "@angular/router";
import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "@modules/auth/services/auth.service";
import {UtilsService} from "@core/services/utils.service";
import {DataService} from "@core/services/data.service";

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent {
  deleteAccountForm: FormGroup;
  loginForm: any;

  constructor(public dialog: MatDialog, private dataService: DataService,
              private authService: AuthService, private router: Router,
              private utils: UtilsService,
              private dialogRef: MatDialogRef<ConfirmDeleteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginForm = this.data.loginForm;
    this.deleteAccountForm = new FormGroup({
      reason: new FormControl(null, [Validators.required]),
      verify_code: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit(): void {
    if (this.deleteAccountForm != undefined) {
      const payload = {
        password: this.loginForm.get('password')?.value,
        phone_number: this.loginForm.get('phone')?.value,
        verify_code: this.deleteAccountForm.get('verify_code')?.value,
        reason: this.deleteAccountForm.get('reason')?.value
      };
      this.dataService.post('login/confirm_delete', payload).then((data) => {
        if (data.status_code === 200) {
          this.utils.openSnackBar("Your Account Deleted Successfully", 5000, 'success');
          this.dialogRef.close('success');
        } else {
          this.utils.openSnackBar(data.data.message, 5000, 'error');
        }
      });
    }
  }
}
