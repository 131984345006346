import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from '@core/services/utils.service';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { DataService } from '@core/services/data.service';
import { MatSidenav } from "@angular/material/sidenav";
import { MapComponent } from 'ngx-mapbox-gl';
import * as mapboxgl from "mapbox-gl";
import { LngLat, LngLatLike, Map, MapboxEvent } from "mapbox-gl";
import * as polyline from "@mapbox/polyline"
import { FormControl } from "@angular/forms";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { environment } from "@env/environment";
import { WebsocketService } from "@core/services/websocket.service";
import { EmptySearchDialogComponent } from "@shared/components/empty-search-dialog/empty-search-dialog.component";
import { ReservationModel } from '@modules/reservation/models';

interface ITripPoint {
  icon: string;
  title: string;
  data: ReservationModel,
  geometry: LngLatLike;
}

@Component({
  selector: 'home-envoy',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})
export class HomeComponent implements OnInit, OnDestroy {
  data= new Array<ReservationModel>();
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  @ViewChild(MapComponent) mapComponent!: MapComponent;
  mapCenter:LngLatLike = [-99.248091, 39.091119];
  driverMarkers: any = [];
  cursorStyle: string = "";
  vehicle_types: any;
  totalCounts: any;
  filterStatus: any;
  filterVehicleType: any;
  totalVehiclesCounts: any = {};
  openedTrip = false;
  hoveredTrip: ReservationModel | undefined;
  openedTripData: ReservationModel | undefined;
  tripPoints: ITripPoint[] = [];
  trips: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
  dataLine: GeoJSON.FeatureCollection<GeoJSON.LineString> | undefined;
  dataLinePassed: GeoJSON.FeatureCollection<GeoJSON.LineString> | undefined;
  geometry: GeoJSON.GeometryObject = {
    type: 'MultiPoint' as const,
    coordinates: [],
  };

  //refs
  _mapRef: Map | undefined;
  searchControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  title = 'socketrv';
  content = '';
  pageIndex = 1;
  dialogNoResult = false;
  constructor(
    public dialog: MatDialog,
    public utils: UtilsService,
    public router: Router,
    public auth: AuthService,
    private dataService: DataService,
    private WebsocketService: WebsocketService
  ) {
    this.utils.setTitle('Home');
  }

  ngOnInit(hideProgress=false): void {
    this.getReservations(hideProgress);
    if(this.utils.handleAll) {
      clearInterval(this.utils.handleAll);
    }
    this.utils.handleAll = setInterval(()=>{this.getReservations(true)}, 5000);
  }

  getLookup(){
    this.dataService.get('admin/reservations/counts?live=1' , true).then(data => {
      if (data.status_code === 200) {
        this.totalCounts = data.data.reservation_counts;
        this.totalVehiclesCounts = data.data.vehicle_counts;
      }
    });

    this.dataService.get('lookup/vehicle_types' , true).then(data => {
      if (data.status_code === 200) {
        this.vehicle_types = data.data;
      }
    });
  }

  getReservations(hideProgress=false) {
    if (!this.openedTrip) {
      this.getLookup();
      if (this.utils.handle) {
        clearInterval(this.utils.handle);
      }
      let query = "";
      if (this.searchControl.value) {
        query = "&search=" + encodeURIComponent(this.searchControl.value);
      }
      if (this.filterStatus) {
        query += "&status=" + encodeURIComponent(this.filterStatus);
      }
      if (this.filterVehicleType) {
        query += "&filter_vehicle_type=" + (this.filterVehicleType);
      }
      this.dataService.get('admin/reservations/live?page_size=200'+ query, hideProgress).then(data => {
        if (data.status_code === 200) {
          if(this.pageIndex === 1) {
            this.driverMarkers = [];
            this.data = data.data;
          }
          this.prepareMarkers(data.data);
          if (data.total_count === 0 && (this.searchControl.value || this.filterStatus || this.filterVehicleType) && !this.dialogNoResult && !hideProgress ) {
            this.dialogNoResult = true;
            const dialogRef = this.dialog.open(EmptySearchDialogComponent, {
              width: '350px',
            });
            dialogRef.afterClosed().subscribe((status) => {
              this.dialogNoResult = false;
            });
          }

        }
      });
    }
  }

  /**
   * view trip info on hover
   * @param feature
   */
  viewInfo(feature: GeoJSON.Feature) {
    this.cursorStyle= 'pointer';
    this.hoveredTrip = this.data.find(res => res.id === feature.properties?.['id']);
  }

  /**
   * hide trip info on mouse leave
   */
  hideInfo() {
    this.cursorStyle = '';
    this.hoveredTrip = undefined;
  }

  openTrip(feature: GeoJSON.Feature) {
    this.getLookup();
    this.openedTripData = this.data.find(res => res.id === feature.properties?.['id']);

    if (this.openedTripData) {
      let track = {
        user_id: this.openedTripData.driver.id,
        reservation_id: this.openedTripData.id
      };
      this.openedTrip = true;
      this.LiveTracker(track);

      if(this.utils.handle) {
        clearInterval(this.utils.handle);
      }

      this.utils.handle = setInterval(()=>{this.LiveTracker(track)}, 5000);

      if ("coordinates" in this.geometry) {
        this.geometry.coordinates = [];
      }

      this.mapCenter = [this.openedTripData.geo_direction[0].location[0], this.openedTripData.geo_direction[0].location[1]];
      this._mapRef?.setZoom(11.15);
      const polylines = polyline.decode(this.openedTripData.geo_polyline);

      polylines.map((step:any) => {
        if ("coordinates" in this.geometry) {
          // @ts-ignore
          this.geometry.coordinates.push([step[1],step[0]]);
        }
      });

      if ("coordinates" in this.geometry && this.geometry.coordinates.length > 0) {
        // @ts-ignore
        this.dataLine = {type: 'FeatureCollection',features: [{type: 'Feature',properties: {},geometry: {type:"LineString",coordinates:this.geometry.coordinates}}]};
      }

      this.sidenav?.open();
    }
  }

  LiveTracker(track: any){
    this.WebsocketService.messages.subscribe(msg => {
      if (this.driverMarkers.length == 0 && msg.lan && msg.lat){
        const lat = parseFloat(msg.lat)
        const lan = parseFloat(msg.lan)
        this.mapComponent.center = [ lan,  lat]
        this.mapCenter = [ lan,  lat]

        this.mapComponent.zoom = [13];
        this._mapRef?.setZoom(13)
      }
      this.addMarkerDriver(msg.lat, msg.lan)
    });

    this.WebsocketService.messages.next(track);
    if (this.openedTripData) {
      this.dataService.get("admin/reservations/" + this.openedTripData.id, true).then(data => {
        if (data.status_code === 200) {
          this.updateOpenedTrip(data.data);
        }
      });
    }
  }

  /**
   * update opened trip with live data and update route points
   * @param trip
   */
  updateOpenedTrip(trip: ReservationModel) {
    this.openedTripData = trip;
    trip.reservation_addresses?.map(address => {
      if (address.type !== 'EN_ROUTE') {
        const lngLat = [address.address.geo_location[0], address.address.geo_location[1]];
        this.addTripPoint(lngLat, address.type);
      }
    });
  }

  /**
   * add points to the route of the opened trip
   * @param lngLat
   * @param type
   */
  addTripPoint(lngLat: string[], type: string) {
    this.tripPoints.push({
      title: type,
      geometry: [+lngLat[0], +lngLat[1]],
      icon: this.getMarkerIcon(type),
      data: (this.openedTripData as ReservationModel)
    });
  }

  toggleSideNav(){
    this.sidenav?.toggle();
  }

  onMapLoad(map: MapboxEvent){
    this._mapRef = map.target;

    const geocoder = new MapboxGeocoder(
      {
        accessToken: environment.mapbox.accessToken,
        marker: false,
      }
    );
    this._mapRef?.dragRotate.disable();
    this._mapRef?.addControl(new mapboxgl.NavigationControl(),"bottom-right");
    const southWest = new LngLat ( -131.946761,22.124762),
      northEast = new LngLat( -52.654671,50.787069),
      bounds = new mapboxgl.LngLatBounds(southWest, northEast);
    this._mapRef?.setMaxBounds(bounds)
    this._mapRef?.setMinZoom(4)
    this._mapRef?.setMaxZoom(16)

    //this._mapRef?.addControl(geocoder,"bottom-right");
  }

  mapResize(){
    this._mapRef?.resize()
  }

  closeTrip(){
    this.utils.showProgressDialog()
    this.openedTrip = false;
    this.openedTripData = undefined;
    this.pageIndex = 1;
    this.getReservations();
    this.dataLine = {type: 'FeatureCollection',features: [{type: 'Feature',properties: {},geometry: {type:"LineString",coordinates:[]}}]};
    this.utils.closeProgressDialog();
    if(this.utils.handle) {
      clearInterval(this.utils.handle);
    }
  }

  addMarkerDriver(lat:any, lng:any) {
    if(lat !=0 && lng != 0) {
      this.driverMarkers = [{
        position: {
          lat: lat,
          lng: lng,
        },
        options: {
          icon: '/assets/icons/car.png'
        },
      }];
    }
  }

  filterReservationStatus(status:string){
    if(!this.openedTrip) {
      this.filterStatus = status;
      this.getReservations()
    }
  }

  filterReservationVehicle(vehicle_id:number){
    if(!this.openedTrip) {
      this.filterVehicleType = vehicle_id;
      this.getReservations()
    }
  }

  /**
   * preparing reservations to create clusters and markers for each trip
   * @param reservations
   */
  prepareMarkers(reservations: ReservationModel[]) {
    const _features: GeoJSON.Feature[] = [];
    for (const reservation of reservations) {
      if (typeof (reservation.geo_direction) !== "string") {
        if (reservation.geo_direction && reservation.geo_direction[0] && reservation.geo_direction[0].location) {
          const lng = reservation.geo_direction[0].location[0];
          const lat = reservation.geo_direction[0].location[1];
          _features.push({
            type: 'Feature',
            properties: {
              title: reservation.status,
              icon: this.getMarkerIcon(reservation.status),
              ...reservation
            },
            geometry: {
              type: "Point",
              coordinates: [lng, lat]
            }
          });
        }
      }
    }
    this.trips = {
      type: "FeatureCollection",
      features: _features
    }
  }

  statusName(name: any){
    if (name && this.totalCounts && this.totalCounts[name]) {
      return this.totalCounts[name].name;
    }
    return name;
  }

  getMarkerIcon(status: string): string {
    switch (status) {
      case "Dropped":
        return '/assets/icons/green.png';
      case "Booked":
        return '/assets/icons/gray.png';
      case "En_route":
        return '/assets/icons/orange.png';
      case "On_Location":
        return '/assets/icons/orange.png';
      case "On_Board":
        return '/assets/icons/orange.png';
      case "PICKUP":
        return '/assets/icons/PicUp.png';
      case "DROPOFF":
        return '/assets/icons/DropOffIcon.png';
      default:
        return '/assets/icons/gray.png';
    }
  }

  ngOnDestroy() {
    this.utils.clear_interval();
  }
}
