import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { TwoFactorComponent } from "../two-factor/two-factor.component";
import { UtilsService } from '@core/services/utils.service';
import { DataService } from '@core/services/data.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit{
  loginForm = this.initFormGroup();
  invalidCredentials: boolean;
  maximumAllowedAttempts: boolean;
  hide: boolean;
  loginFormIsValid : boolean = false;

  constructor(public dialog: MatDialog, private dataService: DataService,
              private utils: UtilsService, private fb: FormBuilder) {
    this.invalidCredentials = false;
    this.maximumAllowedAttempts = false;
    this.hide = true;
  }

  ngOnInit(){
    this.loginForm.valueChanges.subscribe(() => {
      this.checkLoginFormValidity();
    });
  }

  onSubmit(): void {
    // if (this.loginForm.invalid) {
    //   this.loginForm.markAllAsTouched();
    //   return;
    // }
    const payload = {
      password: this.loginForm.get('password')?.value,
      phone_number: this.loginForm.get('phone')?.value
    };
    this.dataService.post('admin/login/send_verify', payload).then((data) => {
      if (data.status_code === 200) {
        this.verifyLogin()
      } else {
        this.utils.openSnackBar(data.data.message, 5000, 'error');
      }
    });
  }

  verifyLogin() {
    const dialogRef = this.dialog.open(TwoFactorComponent, {
      disableClose: true,
      width: '550px',
      data: { loginForm: this.loginForm }
    });
    dialogRef.afterClosed().subscribe((status) => {
      if (status == 'success') {
        this.dialog.closeAll()
      }
    });
  }

  private initFormGroup() {
    return this.fb.nonNullable.group({
      password: [null, [Validators.required]],
      phone: [null, [Validators.required]]
    })
  }

  checkLoginFormValidity() {
    const phoneNumberControl = this.loginForm.get('phone');
    const passwordControl = this.loginForm.get('password');

    if (phoneNumberControl !== null && passwordControl !== null &&
      phoneNumberControl.value !== null && passwordControl.value !== null) {
        const phoneNumber = phoneNumberControl.value as string;
        const password = passwordControl.value as string;
        const isValidPhoneNumber = phoneNumber.trim().length > 0;
        const isValidPassword = password.trim().length > 0;
        this.loginFormIsValid = isValidPhoneNumber && isValidPassword;
      }
  }
}
