import { NgModule } from '@angular/core';
import { NgOptimizedImage } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { GeneralInterceptor } from "@core/interceptors/general.interceptor";
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from "@env/environment";

import { CoreModule } from "./core/core.module";
import { SharedModule } from "@shared/shared.module";
import { AuthModule } from "@modules/auth/auth.module";
import { AppRoutingModule } from './app-routing.module';
import { MaterialsModule } from "./materials/materials.module";

import { AppComponent } from './app.component';
import { HomeComponent } from "./home/home.component";
import { LandingComponent } from './landing/landing.component';
import { MessagingComponent } from './messaging/messaging.component';
import { MarketingComponent } from './marketing/marketing.component';
import { HelpAdminComponent } from './help-admin/help-admin.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ConfirmDeleteComponent } from './delete-account/confirm-delete/confirm-delete.component';
import { HelpAdminWebviewComponent } from './help-admin-webview/help-admin-webview.component';
import { SystemSearchComponent } from './core/components/header/system-search/system-search.component';
import { CreateServiceAreasConfigurationItemComponent } from './modules/settings/components/create-service-areas-configuration-item/create-service-areas-configuration-item.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LandingComponent,
    MessagingComponent,
    SystemSearchComponent,
    HelpAdminWebviewComponent,
    HelpAdminComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    MarketingComponent,
    DeleteAccountComponent,
    ConfirmDeleteComponent,
    CreateServiceAreasConfigurationItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialsModule,
    HttpClientModule,
    SharedModule,
    AuthModule,
    CoreModule,
    NgOptimizedImage,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    ReactiveFormsModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GeneralInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
