import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@modules/auth/services/auth.service';
import { UtilsService } from '@core/services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private utils: UtilsService
  ) {
  }

  get(apiURL: string, hideProgress?: boolean): Promise<any> {
    if (!hideProgress) {
      this.utils.showProgressDialog();
    }
    return this.http
      .get(apiURL)
      .toPromise()
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  put(apiURL: string, body: any, hideProgress?: boolean): Promise<any> {
    if (!hideProgress) {
      this.utils.showProgressDialog();
    }
    return this.http
      .put(apiURL, body)
      .toPromise()
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  post(apiURL: string, body: any, hideProgress?: boolean): Promise<any> {
    if (!hideProgress) {
      this.utils.showProgressDialog();
    }
    return this.http
      .post(apiURL, body)
      .toPromise()
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  delete(apiURL: string, hideProgress?: boolean): Promise<any> {
    if (!hideProgress) {
      this.utils.showProgressDialog();
    }
    return this.http
      .delete(apiURL)
      .toPromise()
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  private handleError(error: any): any {
    this.utils.closeProgressDialog();
    if (error.status === 401) {
      //return this.auth.logout();
    } else if (error.status === 403) {
      if (error.error?.data?.message) {
        this.utils.openSnackBar(error.error?.data?.message, 5000, 'error');
      }
    } else if (error.status === 409) {
      let msg = 'Conflict Error.';
      if (error.error?.data?.['fields_error']) {
        msg = (Object.values(error.error?.data?.['fields_error'])[0] as string[])[0];
      } else {
        msg = error.error?.data?.message || msg;
      }
      error.error.data = { ...error.error.data, message: msg };
      this.utils.openSnackBar(msg, 5000, 'error');
    } else {
      if (error.error?.data?.message) {
        this.utils.openSnackBar(error.error?.data?.message, 5000, 'error');
      }
    }
    return { hasError: true, status_code: error.status, error: error.error, data: error.error?.data };
  }

  private handleResponse(response: any): any {
    this.utils.closeProgressDialog();
    if (response.status_code === 401) {
      return this.auth.logout();
    } else if (response.status_code === 403) {
      if (response.data.message) {
        this.utils.openSnackBar(response.data.message, 5000, 'error');
      }
    } else if (
      response.data &&
      response.data.message === 'User already logged in'
    ) {
      //this.auth.logout();
    } else if (response.status_code === 500) {
      this.utils.openSnackBar('INTERNAL_SERVER_ERROR', 5000, 'error');
      window.scrollTo(0, 0);
    }
    return response;
  }
}
