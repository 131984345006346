import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    MatFormFieldModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDividerModule,
    MatRippleModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatMomentDateModule,
    DragDropModule
  ],
  exports: [
    CdkTableModule,
    CdkTreeModule,
    MatFormFieldModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDividerModule,
    MatRippleModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTreeModule,
    DragDropModule
  ],
  declarations: [],
  providers: [
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})

export class MaterialsModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('customers_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customers.svg'));
    matIconRegistry.addSvgIcon('customers_icon_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customers-open.svg'));
    matIconRegistry.addSvgIcon('drivers_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drivers.svg'));
    matIconRegistry.addSvgIcon('drivers_icon_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drivers-open.svg'));
    matIconRegistry.addSvgIcon('trips_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trips.svg'));
    matIconRegistry.addSvgIcon('trips_icon_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-open.svg'));
    matIconRegistry.addSvgIcon('trips_icon_filled', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trips_icon_filled.svg'));
    matIconRegistry.addSvgIcon('live_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/map.svg'));
    matIconRegistry.addSvgIcon('live_icon_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/map-open.svg'));
    matIconRegistry.addSvgIcon('settings_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/setting.svg'));
    matIconRegistry.addSvgIcon('settings_icon_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/setting_open.svg'));
    matIconRegistry.addSvgIcon('dashboard_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard.svg'));
    matIconRegistry.addSvgIcon('car_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/car.svg'));
    matIconRegistry.addSvgIcon('car_frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/car_frame.svg'));
    matIconRegistry.addSvgIcon('location_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/location.svg'));
    matIconRegistry.addSvgIcon('presentation_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/presention-chart.svg'));
    matIconRegistry.addSvgIcon('routing_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/routing.svg'));
    matIconRegistry.addSvgIcon('search_normal_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search-normal.svg'));
    matIconRegistry.addSvgIcon('search_normal_icon_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search-normal-open.svg'));
    matIconRegistry.addSvgIcon('sms_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sms.svg'));
    matIconRegistry.addSvgIcon('sms_open_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sms_open.svg'));
    matIconRegistry.addSvgIcon('send_sms_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/send-sms.svg'));
    matIconRegistry.addSvgIcon('car_types_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/car_types.svg'));
    matIconRegistry.addSvgIcon('current_active_trip_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/current_active_trip.svg'));
    matIconRegistry.addSvgIcon('arrow-circle-left', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-circle-left.svg'));
    matIconRegistry.addSvgIcon('car-type-filter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/car-type-filter.svg'));
    matIconRegistry.addSvgIcon('car-type-filter-opened', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/car-type-filter-opened.svg'));
    matIconRegistry.addSvgIcon('car-type-filter-empty', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/car-type-filter-empty.svg'));
    matIconRegistry.addSvgIcon('trip-station-filter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-station-filter.svg'));
    matIconRegistry.addSvgIcon('trip-station-filter-opened', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-station-filter-opened.svg'));
    matIconRegistry.addSvgIcon('trip-station-filter-empty', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-station-filter-empty.svg'));
    matIconRegistry.addSvgIcon('actions-log', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions-log.svg'));
    matIconRegistry.addSvgIcon('customer-data', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customer-data.svg'));
    matIconRegistry.addSvgIcon('driver-data', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/driver-data.svg'));
    matIconRegistry.addSvgIcon('trip-preferences', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-preferences.svg'));
    matIconRegistry.addSvgIcon('trip-statuses', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-statuses.svg'));
    matIconRegistry.addSvgIcon('trips-stations', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trips-stations.svg'));
    matIconRegistry.addSvgIcon('drivers_active_count', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drivers_active_count.svg'));
    matIconRegistry.addSvgIcon('drivers_expired_count', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drivers_expired_count.svg'));
    matIconRegistry.addSvgIcon('drivers_nearly_count', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drivers_nearly_count.svg'));
    matIconRegistry.addSvgIcon('drivers_total_count', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drivers_total_count.svg'));
    matIconRegistry.addSvgIcon('table-customization', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/table-customization.svg'));
    matIconRegistry.addSvgIcon('menu-selected', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-customization.svg'));
    matIconRegistry.addSvgIcon('menu-not-selected', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-black.svg'));
    matIconRegistry.addSvgIcon('eye-hide', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye-hide.svg'));
    matIconRegistry.addSvgIcon('eye-show', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye-show.svg'));
    matIconRegistry.addSvgIcon('green-tick', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green-tick.svg'));
    matIconRegistry.addSvgIcon('close-circle', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close-circle.svg'));
    matIconRegistry.addSvgIcon('close-circle_grey', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close-circle_grey.svg'));
    matIconRegistry.addSvgIcon('filter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter-new.svg'));
    matIconRegistry.addSvgIcon('user-tick', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-tick.svg'));
    matIconRegistry.addSvgIcon('user-tick-frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-tick-frame.svg'));
    matIconRegistry.addSvgIcon('user-tick-blue', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-tick-blue.svg'));
    matIconRegistry.addSvgIcon('user-minus', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-minus.svg'));
    matIconRegistry.addSvgIcon('user-minus-frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-minus-frame.svg'));
    matIconRegistry.addSvgIcon('service-area-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/service-area-icon.svg'));
    matIconRegistry.addSvgIcon('calender-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calender-icon.svg'));
    matIconRegistry.addSvgIcon('active_customers_counts', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/active_customers_counts.svg'));
    matIconRegistry.addSvgIcon('recently_customers_counts', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/recently_customers_counts.svg'));
    matIconRegistry.addSvgIcon('total_customers_counts', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/total_customers_counts.svg'));
    matIconRegistry.addSvgIcon('reset-all-filter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/reset-all-filter.svg'));
    matIconRegistry.addSvgIcon('blue_frame_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/blue_frame_icon.svg'));
    matIconRegistry.addSvgIcon('gray_frame_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/gray_frame_icon.svg'));
    matIconRegistry.addSvgIcon('green_frame_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/green_frame_icon.svg'));
    matIconRegistry.addSvgIcon('orange_frame_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/orange_frame_icon.svg'));
    matIconRegistry.addSvgIcon('key_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/key_icon.svg'));
    matIconRegistry.addSvgIcon('key_active_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/key_active_icon.svg'));
    matIconRegistry.addSvgIcon('notification_icon_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification_icon_open.svg'));
    matIconRegistry.addSvgIcon('notification_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification_icon.svg'));
    matIconRegistry.addSvgIcon('no-result-system', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/no-result-system.svg'));
    matIconRegistry.addSvgIcon('VEH_REG', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/VEH_REG.svg'));
    matIconRegistry.addSvgIcon('VEH_INSURANCE', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/VEH_REG.svg'));
    matIconRegistry.addSvgIcon('DRUG_TEST', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/DRUG_TEST.svg'));
    matIconRegistry.addSvgIcon('DRIVER_LICENSE', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/DRIVER_LICENSE.svg'));
    matIconRegistry.addSvgIcon('documents-driver', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/documents-driver.svg'));
    matIconRegistry.addSvgIcon('availability-driver', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/availability-driver.svg'));
    matIconRegistry.addSvgIcon('experience-driver', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/experienc-driver.svg'));
    matIconRegistry.addSvgIcon('outlines-requirement', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/outlines-requirement.svg'));
    matIconRegistry.addSvgIcon('bank-data-driver', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bank-data-driver.svg'));
    matIconRegistry.addSvgIcon('urgent-trip-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/urgent-trip-icon.svg'));
    matIconRegistry.addSvgIcon('TRIP_URGENT', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/TRIP_URGENT.svg'));
    matIconRegistry.addSvgIcon('NO_ACTIVE_LIVE', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/TRIP_URGENT.svg'));
    matIconRegistry.addSvgIcon('TRIP', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/TRIP_URGENT.svg'));
    matIconRegistry.addSvgIcon('TRIP_ASSIGNED', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/TRIP_URGENT.svg'));
    matIconRegistry.addSvgIcon('DRIVER', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/DRIVER.svg'));
    matIconRegistry.addSvgIcon('PROFILE', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/PROFILE.svg'));
    matIconRegistry.addSvgIcon('DOCUMENT_NEARLY_EXPIRED', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/DOCUMENT_NEARLY_EXPIRED.svg'));
    matIconRegistry.addSvgIcon('EXPIRED_DOC', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/EXPIRED_DOC.svg'));
    matIconRegistry.addSvgIcon('GENERAL', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/GENERAL.svg'));
    matIconRegistry.addSvgIcon('messeges-search', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/messeges-search.svg'));
    matIconRegistry.addSvgIcon('customer-icon-data', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customer-icon-data.svg'));
    matIconRegistry.addSvgIcon('help-admin-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/help-admin-icon.svg'));
    matIconRegistry.addSvgIcon('tick-circle', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tick-circle.svg'));
    matIconRegistry.addSvgIcon('arrow-right', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right.svg'));
    matIconRegistry.addSvgIcon('user-square', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-square.svg'));
    matIconRegistry.addSvgIcon('user', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg'));
    matIconRegistry.addSvgIcon('user-add', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-add.svg'));
    matIconRegistry.addSvgIcon('menu-board', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-board.svg'));
    matIconRegistry.addSvgIcon('call', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/call.svg'));
    matIconRegistry.addSvgIcon('call_default', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/call_default.svg'));
    matIconRegistry.addSvgIcon('calendar', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg'));
    matIconRegistry.addSvgIcon('trash', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg'));
    matIconRegistry.addSvgIcon('card-pos', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/card-pos.svg'));
    matIconRegistry.addSvgIcon('dollar-circle', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dollar-circle.svg'));
    matIconRegistry.addSvgIcon('info-circle', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info-circle.svg'));
    matIconRegistry.addSvgIcon('archive-book', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/archive-book.svg'));
    matIconRegistry.addSvgIcon('note', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/note.svg'));
    matIconRegistry.addSvgIcon('gps', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/gps.svg'));
    matIconRegistry.addSvgIcon('no-content', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/no-content.svg'));
    matIconRegistry.addSvgIcon('route-square', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/route-square.svg'));
    matIconRegistry.addSvgIcon('edit', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg'));
    matIconRegistry.addSvgIcon('story', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/story.svg'));
    matIconRegistry.addSvgIcon('story_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/story_open.svg'));
    matIconRegistry.addSvgIcon('total_corporates_counts', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/total_corporates_counts.svg'));
    matIconRegistry.addSvgIcon('active_corporates_counts', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/active_corporates_counts.svg'));
    matIconRegistry.addSvgIcon('recent_corporates_counts', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/recent_corporates_counts.svg'));
    matIconRegistry.addSvgIcon('direct_box', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/directbox-default.svg'));
    matIconRegistry.addSvgIcon('export', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/export.svg'));
    matIconRegistry.addSvgIcon('import', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/import.svg'));
    matIconRegistry.addSvgIcon('grid-edit', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/grid-edit.svg'));
    matIconRegistry.addSvgIcon('filter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter.svg'));
    matIconRegistry.addSvgIcon('buildings', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/buildings.svg'));
    matIconRegistry.addSvgIcon('buildings-2', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/buildings-2.svg'));
    matIconRegistry.addSvgIcon('buildings-2', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/buildings-2.svg'));
    matIconRegistry.addSvgIcon('user-circle-add', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-circle-add.svg'));
    matIconRegistry.addSvgIcon('captain-hat-profile', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/game-icons_captain-hat-profile.svg'));
    matIconRegistry.addSvgIcon('user-tag', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-tag.svg'));
    matIconRegistry.addSvgIcon('flash-circle', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/flash-circle.svg'));
    matIconRegistry.addSvgIcon('notification-bing', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification-bing.svg'));
    matIconRegistry.addSvgIcon('security-time', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/security-time.svg'));
    matIconRegistry.addSvgIcon('timer', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/timer.svg'));
    matIconRegistry.addSvgIcon('main-component', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/main-component.svg'));
    matIconRegistry.addSvgIcon('main-component_black', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/main-component_black.svg'));
    matIconRegistry.addSvgIcon('main-component_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/main-component_open.svg'));
    matIconRegistry.addSvgIcon('main-component_outlined', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/main-component_outlined.svg'));
    matIconRegistry.addSvgIcon('devices', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/devices.svg'));
    matIconRegistry.addSvgIcon('devices_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/devices_open.svg'));
    matIconRegistry.addSvgIcon('shield', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shield.svg'));
    matIconRegistry.addSvgIcon('gear', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/gear.svg'));
    matIconRegistry.addSvgIcon('gear_black', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/gear_black.svg'));
    matIconRegistry.addSvgIcon('gear_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/gear_open.svg'));
    matIconRegistry.addSvgIcon('people', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/people.svg'));
    matIconRegistry.addSvgIcon('people_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/people_open.svg'));
    matIconRegistry.addSvgIcon('setting-panel', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/setting-panel.svg'));
    matIconRegistry.addSvgIcon('setting-panel_black', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/setting-panel_black.svg'));
    matIconRegistry.addSvgIcon('setting-panel_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/setting-panel_open.svg'));
    matIconRegistry.addSvgIcon('dollar-square', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dollar-square.svg'));
    matIconRegistry.addSvgIcon('dollar-square_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dollar-square_open.svg'));
    matIconRegistry.addSvgIcon('people_black', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/people_black.svg'));
    matIconRegistry.addSvgIcon('user-add_primary', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-add_primary.svg'));
    matIconRegistry.addSvgIcon('close-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close-icon.svg'));
    matIconRegistry.addSvgIcon('arrow-left_filled', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-left_filled.svg'));
    matIconRegistry.addSvgIcon('invoice', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice.svg'));
    matIconRegistry.addSvgIcon('invoice_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice_open.svg'));
    matIconRegistry.addSvgIcon('dollar-circle-box', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dollar-circle-box.svg'));
    matIconRegistry.addSvgIcon('receipt-discount', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/receipt-discount.svg'));
    matIconRegistry.addSvgIcon('timer_frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/timer_frame.svg'));
    matIconRegistry.addSvgIcon('arrow-up', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-up.svg'));
    matIconRegistry.addSvgIcon('receipt-gray', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/receipt-gray.svg'));
    matIconRegistry.addSvgIcon('undo-box', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/undo-box.svg'));
    matIconRegistry.addSvgIcon('pencil-edit', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pencil-edit.svg'));
    matIconRegistry.addSvgIcon('clipboard-tick', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/clipboard-tick.svg'));
    matIconRegistry.addSvgIcon('user-octagon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-octagon.svg'));
    matIconRegistry.addSvgIcon('verify_filled', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/verify_filled.svg'));
    matIconRegistry.addSvgIcon('verify_outlined', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/verify_outlined.svg'));
    matIconRegistry.addSvgIcon('calendar-edit', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar-edit.svg'));
    matIconRegistry.addSvgIcon('no-documents', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/no-documents.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'download_pdf', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/import.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'card-pos', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/card-pos.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'printer', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/printer.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'printer_outlined', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/printer_outlined.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'moneys', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/moneys.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'money-tick', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/money-tick.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'money-cross', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/money-cross.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'moneys_card', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/moneys_card.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'discover', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/discover.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'convert-card', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/convert-card.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'convert-card_primary', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/convert-card_primary.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'convert-card__icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/convert-card__icon.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'receipt', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/receipt.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'receipt-add', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/receipt-add.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'receipt-minus', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/receipt-minus.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'archive', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/archive.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'verify_outlined_primary', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/verify_outlined_primary.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'no-invoice-data', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/no-invoice-data.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'file-type-excel', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/file-type-excel.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'file-type-excel_active', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/file-type-excel_active.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'file-type-pdf', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/file-type-pdf.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'file-type-pdf_active', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/file-type-pdf_active.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'receipt-discount_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/receipt-discount_icon.svg'));
    matIconRegistry.addSvgIconInNamespace('invoicing', 'wallet', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/wallet.svg'));
    matIconRegistry.addSvgIconSetInNamespace('invoicing', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/directbox.svg'));
    matIconRegistry.addSvgIconSetInNamespace('invoicing', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/invoice/receipt-edit.svg'));
    matIconRegistry.addSvgIconSetInNamespace('cov', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/smart-car.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'note', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/note.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'clock', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/clock.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'status', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/status.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'export', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/export.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'discover', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/discover.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'category_frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/category_frame.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'delete-doc', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/delete-doc.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'info', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/info.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'lights', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/lights.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'vehicle-inside', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/vehicle-inside.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'vehicle-outside', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/vehicle-outside.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'task-square', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/task-square.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'document-upload', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/document-upload.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'gallery-export', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/gallery-export.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'car-frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/car-frame.svg'));
    matIconRegistry.addSvgIconInNamespace('cov', 'license-frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle/license-frame.svg'));
    matIconRegistry.addSvgIcon('message-card-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/message-card-icon.svg'));
    matIconRegistry.addSvgIcon('contact-card-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/contact-card-icon.svg'));
    matIconRegistry.addSvgIcon('trip-routing-card', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-routing-card.svg'));
    matIconRegistry.addSvgIcon('trip-times-card', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-times-card.svg'));
    matIconRegistry.addSvgIcon('mail-card-icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/mail-card-icon.svg'));
    matIconRegistry.addSvgIcon('email_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/email_icon.svg'));
    matIconRegistry.addSvgIcon('log_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/log_icon.svg'));
    matIconRegistry.addSvgIcon('add-card', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-card.svg'));
    matIconRegistry.addSvgIcon('block', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/block.svg'));
    matIconRegistry.addSvgIcon('copy', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/copy.svg'));
    matIconRegistry.addSvgIcon('bill', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bill.svg'));
    matIconRegistry.addSvgIcon('bank_icon', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bank_icon.svg'));
    matIconRegistry.addSvgIcon('message-question', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/message-question.svg'));
    matIconRegistry.addSvgIcon('cup', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cup.svg'));
    matIconRegistry.addSvgIcon('search-advanced', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search-advanced.svg'));
    matIconRegistry.addSvgIcon('filter-search', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter-search.svg'));
    matIconRegistry.addSvgIcon('scan_barcode', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/scan_barcode.svg'));
    matIconRegistry.addSvgIcon('filter_active', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter_active.svg'));
    matIconRegistry.addSvgIcon('dispatch-note-frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dispatch-note-frame.svg'));
    matIconRegistry.addSvgIcon('pickup-note-frame', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pickup-note-frame.svg'));
    matIconRegistry.addSvgIcon('user-edit', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-edit.svg'));
    matIconRegistry.addSvgIconInNamespace('credit', 'visa', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-cards/visa.svg'));
    matIconRegistry.addSvgIconInNamespace('credit', 'amex', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-cards/am_express.svg'));
    matIconRegistry.addSvgIconInNamespace('credit', 'discover', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-cards/discover.svg'));
    matIconRegistry.addSvgIconInNamespace('credit', 'unionpay', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-cards/union_pay.svg'));
    matIconRegistry.addSvgIconInNamespace('credit', 'mastercard', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-cards/mastercard.svg'));
    matIconRegistry.addSvgIconInNamespace('credit', 'diners', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-cards/diners.svg'));
    matIconRegistry.addSvgIconInNamespace('credit', 'jcb', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-cards/jcb.svg'));
    matIconRegistry.addSvgIcon('trip-type-card', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trip-type-card.svg'));
    matIconRegistry.addSvgIcon('grey-station', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/grey-station.svg'));
  }
}
