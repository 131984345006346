import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { collection, Firestore, onSnapshot, orderBy, query } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  chats$ = new BehaviorSubject<any[]>([]);
  unreadChatCount$ = new BehaviorSubject(0);

  constructor(private firestore: Firestore) {
  }

  async getUnreadChats() {
    const _c = collection(this.firestore, environment.firestore_chat);
    const _q = query(_c, orderBy('date', 'desc'));
    onSnapshot(_q, (snapshot) => {
      let unread = 0;
      const chats: any[] = [];
      snapshot.forEach(doc => {
        chats.push({ driver_id: doc.id, data: doc.data() });
        if (doc.data()['date']?.seconds > doc.data()['read']?.seconds) {
          unread += 1;
        }
      });
      this.chats$.next(chats);
      this.unreadChatCount$.next(unread);
    });
  }
}
