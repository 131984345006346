import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {DataService} from "@core/services/data.service";
import {UtilsService} from "@core/services/utils.service";
import {AuthService} from "@modules/auth/services/auth.service";
import {ConfirmDeleteComponent} from "./confirm-delete/confirm-delete.component";

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit  {
  loginForm: FormGroup;
  hide: boolean;
  deletedSuccess = false;

  constructor(public dialog: MatDialog, private dataService: DataService,
              private authService: AuthService, private router: Router,
              public utils: UtilsService) {
    this.hide = true;
    this.loginForm = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required, Validators.pattern('^(?:05|5|٥|\u0660\u0665+)[\u0660-\u0669\s0-9]{8}$')])
    });
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()){
      if (this.utils.isDesktop()) {
        this.router.navigateByUrl('/live');
      }
      else{
        this.router.navigateByUrl('/reservations');
      }
    }
  }

  onSubmit(): void {
    if (this.loginForm != undefined) {
      const payload = {
        password: this.loginForm.get('password')?.value,
        phone_number: this.loginForm.get('phone')?.value
      };
      this.dataService.post('login/send_verify', payload).then((data) => {
        if (data.status_code === 200) {
          this.delete_account_popup()
        } else {
          this.utils.openSnackBar(data.data.message, 5000, 'error');
        }
      });
    }
  }

  delete_account_popup(){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      disableClose: true,
      width: '550px',
      data: {loginForm:this.loginForm}
    });
    dialogRef.afterClosed().subscribe((status) => {
      if(status == 'success') {
        this.deletedSuccess = true;
      }
    });
  }
}
