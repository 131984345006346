import { Injectable } from "@angular/core";
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from "@env/environment";

const CHAT_URL = environment.ws_server;


export interface Track {
  user_id: string;
  reservation_id: string;
  lat?:string,
  lan?:string
}

@Injectable({
  providedIn: 'root'
})

export class WebsocketService {
  private subject: AnonymousSubject<MessageEvent> | undefined;
  public messages: Subject<Track>;

  constructor() {
    this.messages = <Subject<Track>>this.connect(CHAT_URL).pipe(
      map(
        (response: MessageEvent): Track => {
          return JSON.parse(response.data);
        }
      )
    );
  }

  public connect(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log("Successfully connected");
    }
    return this.subject;
  }

  private create(url: string | URL): AnonymousSubject<MessageEvent> {
    let ws = new WebSocket(url);

    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      ws.onclose = (e) =>{
        this.subject = undefined;
        obs.complete.bind(obs)
        console.log('Socket is closed. Reconnect will be attempted in 5 second.', e.reason);
        setTimeout(()=>{
          console.log("reconnect");
          this.messages = <Subject<Track>>this.connect(CHAT_URL).pipe(
            map(
              (response: MessageEvent): Track => {
                return JSON.parse(response.data);
              }
            )
          );
        }, 5000);
      };
      return ws.close.bind(ws);
    });
    let observer = {
      error: (e:any)=>{
        console.log(e);
      },
      complete: (e:any)=>{
        console.log(e);
      },
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
          console.log(JSON.stringify(data));

        }
      }
    };
    // @ts-ignore
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }
}
