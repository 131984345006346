<h1 mat-dialog-title>Two Factor Authentication</h1>
<div mat-dialog-content [formGroup]="twoFactorForm">
    <br>
    <mat-form-field appearance="outline" class="w-full">
        <mat-label>Verify Code</mat-label>
        <input (keyup.enter)="onSubmit()" matInput name="verify_code" formControlName="verify_code">
        <mat-error *ngIf="twoFactorForm.get('verify_code')?.errors?.['required']">The Field is Required</mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions class="two-fields">
    <button class="w-6" mat-raised-button color="primary" (click)="onSubmit()">Login</button>
    <button class="w-6 button" mat-button color="primary" mat-dialog-close>Cancel</button>
</div>
