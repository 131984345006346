import { Directive, Input, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@modules/auth/services/auth.service';
import { IStaffPermission, TPermissionMethod } from '@modules/settings/models';
import { take } from 'rxjs';

@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermission implements OnInit {
  private userPermissions: IStaffPermission[] = [];
  private permission: string[] = [];
  private isAdmin: boolean = false;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  private _allowedMethod!: TPermissionMethod[];
  get allowedMethod() {
    return this._allowedMethod;
  }

  @Input('appHasPermissionMethod') set _refMethod(method: ('GET' | 'PUT' | 'POST' | 'DELETE' )| ('GET' | 'PUT' | 'POST' | 'DELETE')[]) {
    this._allowedMethod = typeof method === 'string' ? [method] : method;
  }

  @Input('appHasPermission') set _refPermission(permission: string | string[]) {
    this.permission = typeof permission === 'string' ? [permission] : permission;
  }

  ngOnInit() {
    this.authService.userData.pipe(take(1)).subscribe(user => {
      if (user) {
        this.userPermissions = user.user_permissions ?? [];
        this.isAdmin = user.type === 'ADMIN';
        this.updateView();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['_refPermission'] && !changes['_refPermission'].firstChange) {
      this.viewContainer.clear();
      this.updateView();
    }
  }

  private checkPermission(permission: string[]): boolean {
    if (this.permission.includes('*') && !this.allowedMethod) {
      return true;
    }
    if (!this.allowedMethod) {
      return this.userPermissions.some(userPermission => permission.includes(userPermission.permission.permission_on));
    }
    return this.userPermissions.some(userPermission => 
      userPermission.permission.permission_on.includes(String(permission)) && 
      userPermission.method_allowed.some(method => this.allowedMethod.includes(method))
    );
  }

  private updateView() {
    if (this.isAdmin || this.checkPermission(this.permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
