import { Component } from '@angular/core';

@Component({
  selector: 'app-help-admin',
  templateUrl: './help-admin.component.html',
  styleUrls: ['./help-admin.component.scss']
})
export class HelpAdminComponent {
  constructor() {
  }
}
