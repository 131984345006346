<div class="grid grid-nogutter">
    <div class="hidden md:block md:col-7">
        <div class="landing-banner">
            <div class="landing-banner__content">
                <h1>Simplifying Patient Transport!</h1>
                <p>Efficiently manage all aspects of patient transportation with ease using our user-friendly system,
                    designed to simplify and streamline your operations.</p>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-5">
        <div class="login-form">
            <img alt="envoy-logo" src="assets/logo.svg">
            <h1 class="my-4">Login</h1>
            <div class="w-full">
                <app-sign-in />
            </div>
        </div>
    </div>
</div>