import { Directive, HostBinding } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
  selector: '[envoyCheckEnv]',
  standalone: true
})
export class CheckEnvironment {
  @HostBinding('style.display') value = environment.production ? 'none' : undefined;

  constructor() {
  }

}
